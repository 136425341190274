<template>
  <v-card outlined>
    <v-card-text>
      <v-row dense>
        <v-col cols="8">
          <v-radio-group
            v-model="mainAddressIndex"
            :mandatory="false"
            :key="localAddresses.length"
          >
            <div>
              <v-row
                class="align-center"
                v-for="(address, index) in localAddresses"
                :key="index"
              >
                <v-col cols="1">
                  <v-radio :value="index"></v-radio>
                </v-col>
                <v-col cols="9">
                  <AddressInput
                    v-model="localValues.addresses[index]"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    :disabled="localAddresses.length < 2"
                    icon
                    @click="handleRemoveAddress(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-radio-group>
          <v-btn
            small
            class="primary"
            @click="handleAddAddress"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-plus
            </v-icon>
            Добавить адрес
          </v-btn>
        </v-col>
        <v-col cols="4">
          <div
            class="d-flex"
            v-if="mainImage"
          >
            <div class="mx-0 mt-0 sizePhotoBuilding">
              <img
                class="d-block"
                :src="axios.defaults.baseURL + '/cache/images/thumbnails/' + mainImage.image.path + mainImage.image.file_name"
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import formatters from '@/components/mixins/formatters'
import Address from '@/components/structures/address'
import AddressInput from '@/components/views/admin/common/Address'

export default {
  mixins: [formatters],
  name: 'Address',
  components: { Autocomplete, AddressInput },
  props: {
    addresses: Array,
    mainImage: Object
  },
  data: function () {
    return {
      mainIndex: null,
      localValues: {
        addresses: this.addresses
      }
    }
  },
  computed: {
    localAddresses () {
      return this.localValues.addresses
    },
    mainAddressIndex: {
      get () {
        return this.mainIndex
      },
      set (value) {
        if (value !== undefined) {
          this.handleMainIndexChange(this.mainIndex, value)
          this.mainIndex = value
        }
      }
    }
  },
  watch: {
    'localValues.addresses': {
      handler () {
        this.$emit('update:addresses', this.localValues.addresses)
      },
      deep: true
    },
    addresses: {
      handler (val) {
        if (val) {
          this.localValues.addresses = val
        }
      },
      deep: true
    }
  },
  methods: {
    handleRemoveAddress (index) {
      this.localValues.addresses.splice(index, 1)
      this.setMainIndex()
      this.handleMainIndexChange(this.mainIndex, this.mainIndex)
    },
    handleAddAddress () {
      const address = new Address()
      address.is_main = false
      this.localValues.addresses.push(address)
    },
    handleMainIndexChange (oldIndex, newIndex) {
      this.addresses[oldIndex].is_main = 0
      this.addresses[newIndex].is_main = 1
    },
    setMainIndex () {
      this.mainIndex = 0
      this.addresses.forEach((address, index) => {
        if (address.is_main) {
          this.mainIndex = index
        }
      })
    }
  },
  mounted () {
    this.setMainIndex()
  }
}
</script>

<style scoped>

</style>
