<template>
  <v-row>
    <v-col
      cols="7"
      class="pt-0 pb-0"
    >
      <Autocomplete
        v-model="localAddress.street"
        item-value="streetId"
        item-text="name"
        placeholder="Улица"
        url="/street/autocomplete"
        :chars-to-search="2"
      />
    </v-col>
    <v-col
      cols="5"
      class="pt-0 pb-0"
    >
      <v-text-field
        :value="localAddress.building_number"
        @input="handleInput($event)"
        label="Дом"
        :disabled="!localAddress.street"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import Autocomplete from '@/components/views/admin/common/Autocomplete'

export default {
  name: 'Address',
  components: { Autocomplete },
  props: {
    value: Object
  },
  data: function () {
    return {
      localAddress: this.value
    }
  },
  watch: {
    'localAddress.street': {
      handler: function (newVal, oldVal) {
        if (!(newVal && oldVal && newVal.id === oldVal.id)) {
          this.localAddress.id = null
          this.localAddress.building_number = null
        }
      },
      deep: true
    },
    value: {
      handler (val) {
        if (val) {
          this.localAddress = val
        }
      },
      deep: true
    }
  },
  methods: {
    handleInput (data) {
      this.localAddress.building_number = data
    }
  }
}
</script>

<style scoped>

</style>
